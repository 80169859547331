export default {
  seo: {
    name: "Corentin Hervaud",
    description:
      "Corentin Hervaud is a software developer specializing in creating innovative and modern websites and software using the latest technologies.",
    image: "https://hervaud.fr/og.png",
    locale: "en_US",
    type: "website",
    url: "http://hervaud.fr",
    color: "#C980E6",
  },
};
