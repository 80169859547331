import payload_plugin_qLmFnukI99 from "/builds/curs3_w4ll/portfolio/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/builds/curs3_w4ll/portfolio/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/builds/curs3_w4ll/portfolio/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/curs3_w4ll/portfolio/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_jtc2qNDx4l from "/builds/curs3_w4ll/portfolio/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/builds/curs3_w4ll/portfolio/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/builds/curs3_w4ll/portfolio/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/builds/curs3_w4ll/portfolio/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/builds/curs3_w4ll/portfolio/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/builds/curs3_w4ll/portfolio/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/curs3_w4ll/portfolio/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builds/curs3_w4ll/portfolio/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_hBLWEPkA3b from "/builds/curs3_w4ll/portfolio/node_modules/@storyblok/nuxt/dist/runtime/plugin.js";
import plugin_client_UYiXMU8ZyN from "/builds/curs3_w4ll/portfolio/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import primevue_plugin_egKpok8Auk from "/builds/curs3_w4ll/portfolio/.nuxt/primevue-plugin.mjs";
import plugin_client_XxbS7tGyhp from "/builds/curs3_w4ll/portfolio/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import sentry_client_VdOeuw25Mb from "/builds/curs3_w4ll/portfolio/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/builds/curs3_w4ll/portfolio/.nuxt/sentry-client-config.mjs";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_jtc2qNDx4l,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_hBLWEPkA3b,
  plugin_client_UYiXMU8ZyN,
  primevue_plugin_egKpok8Auk,
  plugin_client_XxbS7tGyhp,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg
]